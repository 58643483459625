import {
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const LoadingPage = () => {
  return (
    <div>
      <Stack
        justifyContent={"center"}
        direction={"row"}
        alignItems={"center"}
        height={"100vh"}
      >
        <Stack>
          <CircularProgress
            size={150}
            thickness={2.5}
          />
          <Typography
            textAlign={"center"}
            fontSize={"1.2rem"}
            fontWeight={500}
          >
            Loading...
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
};

export default LoadingPage;
