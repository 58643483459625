import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Error404 } from "./Modules/Error/404";
import LoadingPage from "./Modules/Layout/LoadingPage";

const Donation = lazy(() => import("./pages/donate"));
const DonationResult = lazy(() => import("./pages/donate-result"));
const FormCombined = lazy(() => import("./pages/form-combined-page"));
const InterestFormPage = lazy(() => import("./pages/interest-form-page"));
const CombinedFormVerification = lazy(() =>
  import("./pages/combined-form-verification")
);
const NewDashboard = lazy(() => import("./pages/newDashboard"));
const Login = lazy(() => import("./pages/login"));
const SuccessPage = lazy(() => import("./pages/success-page"));
const VolunteerInvitationPages = lazy(() =>
  import("./pages/volunteer-invitation-page")
);
const OrientationEventPage = lazy(() =>
  import("./pages/orientation-event-page")
);
const OrientationInvitationPage = lazy(() =>
  import("./pages/orientation-invitation-page")
);
const ProgrammeInvitationPage = lazy(() =>
  import("./pages/programme-invitation-page")
);
const VolunteerRequestAttendancePage = lazy(() =>
  import("./pages/volunteer-request-attendance-page")
);
const ProgrammeEventAttendancePage = lazy(() =>
  import("./pages/programme-event-attendance-page")
);

const ValidityPage = lazy(() => import("./pages/expired-page"));

function App() {
  return (
    <div>
      <Suspense fallback={<LoadingPage />}>
        {process.env.REACT_APP_BUILD_MODE === "demo" && (
          <Routes>
            <Route path="/" element={<Donation />} />
            <Route path="/interest-form" element={<InterestFormPage />} />
            <Route path="/donate-result" element={<DonationResult />} />

            <Route path="/combined-form" element={<FormCombined />} />
            <Route
              path="/combined-form-verification"
              element={<CombinedFormVerification />}
            />
            <Route path="/dashboard/*" element={<NewDashboard />} />
            <Route path="/login/*" element={<Login />} />

            <Route path="/success-page" element={<SuccessPage />} />
            <Route path="/expired-page" element={<ValidityPage />} />

            <Route
              path="/volunteer-invitation/:id"
              element={<VolunteerInvitationPages />}
            />
            <Route
              path="/orientation-event-attendance/:id"
              element={<OrientationEventPage />}
            />
            <Route
              path="/orientation-invitation/:id"
              element={<OrientationInvitationPage />}
            />
            <Route
              path="/invitation-programme-event/:id"
              element={<ProgrammeInvitationPage />}
            />
            <Route
              path="/volunteer-request-attendance/:id"
              element={<VolunteerRequestAttendancePage />}
            />
            <Route
              path="/programme-event-attendance/:id"
              element={<ProgrammeEventAttendancePage />}
            />
            <Route path="*" element={<Error404 />} />
          </Routes>
        )}
        {process.env.REACT_APP_BUILD_MODE === "portal" && (
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard/*" />} />
            <Route path="/dashboard/*" element={<NewDashboard />} />
            <Route path="/login/*" element={<Login />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        )}
        {process.env.REACT_APP_BUILD_MODE === "donation" && (
          <Routes>
            <Route path="/" element={<Donation />} />
            <Route path="/donate-result" element={<DonationResult />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        )}
        {process.env.REACT_APP_BUILD_MODE === "volunteer" && (
          <Routes>
            <Route path="/" element={<InterestFormPage />} />
            <Route path="/combined-form" element={<FormCombined />} />
            <Route
              path="/combined-form-verification"
              element={<CombinedFormVerification />}
            />
            <Route path="/success-page" element={<SuccessPage />} />
            <Route path="/expired-page" element={<ValidityPage />} />
            <Route
              path="/volunteer-invitation/:id"
              element={<VolunteerInvitationPages />}
            />
            <Route
              path="/orientation-event-attendance/:id"
              element={<OrientationEventPage />}
            />
            <Route
              path="/orientation-invitation/:id"
              element={<OrientationInvitationPage />}
            />
            <Route
              path="/invitation-programme-event/:id"
              element={<ProgrammeInvitationPage />}
            />
            <Route
              path="/volunteer-request-attendance/:id"
              element={<VolunteerRequestAttendancePage />}
            />
            <Route
              path="/programme-event-attendance/:id"
              element={<ProgrammeEventAttendancePage />}
            />
            <Route path="*" element={<Error404 />} />
          </Routes>
        )}
      </Suspense>
    </div>
  );
}

export default App;
